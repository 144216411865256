<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/doctors-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="seoKeyword"
                        >Language <strong>*</strong></label
                      >
                      <select
                        required
                        v-model="formData.language_id"
                        id=""
                        class="form-control"
                      >
                        <option
                          v-for="(entry, key) in langs"
                          :key="key"
                          v-bind:value="entry.id"
                          >{{ entry.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Seo Title <strong>*</strong></label>
                      <input
                        v-model="formData.seo_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage"
                        >Seo Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.seo_description"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Title <strong>*</strong></label>
                      <input
                        v-model="formData.title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Sub Title <strong>*</strong></label>
                      <input
                        v-model="formData.sub_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label for="namePage"
                        >Description <strong>*</strong></label
                      >
                      <input
                        v-model="formData.description"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage"> Image<strong>*</strong></label>
                      <input
                        type="file"
                        class="form-control"
                        @change="fileChange('img', $event)"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage">Sub Text<strong>*</strong></label>
                      <input
                        v-model="formData.img_sub_text"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage"
                        >Card Area Title<strong>*</strong></label
                      >
                      <input
                        v-model="formData.card_title"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="slugPage"
                        >Card Area Description<strong>*</strong></label
                      >
                      <input
                        v-model="formData.card_description"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  page: {
    title: "Doctors Page Create",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    // vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Doctors Page Create",
      items: [
        {
          text: "Doctors Page",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {},
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    async FindLp() {
      await API.Get(API.DoctorsPage.LangList).then((response) => {
        if (response.data.status == "success") {
          this.langs = response.data.order;
        } else {
          this.langs = [];
        }
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.DoctorsPage.Create,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;
        return this.$router.push("/doctors-page");
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
